import { isObject } from '@bespohk/lib';
import { j2xParser } from 'fast-xml-parser';

type DocumentHeader = {
  docType: string; // MORRIS Quotation
  docId: string;
  docRevisionNo: number;
  docQuote: string;
};

type Project = {
  projectCode: string;
  projectName: string;
  projectPriority: number;
};

type Customer = {
  customerCode: string;
  customerName: string;
};

type OrderHeader = {
  orderDate: string;
  customer: Customer;
  orderValidTo: string;
  orderWarehouse: string; // SYD
  orderTerritory: string;
  orderReference: string;
  orderRep: string;
  delivery: Delivery;
};

type Delivery = {
  deliveryStreet1: string;
  deliveryStreet2: string; // ""
  deliverySuburb: string;
  deliveryState: string;
  deliveryCountry: string;
  deliveryPostcode: string;
};

type OrderLine = {
  lineNo: number;
  stockCode: string;
  customerPartNo: string;
  lineDescription: string;
  uom: string; // EACH
  orderQuantity: number;
  itemPrice: number;
  discountRate: number;
  lineTotal: number;
};

type Order = {
  orderHeader: OrderHeader;
  orderLines: {
    line: OrderLine[];
  };
};

type BOMHeader = {
  bomItemCode: string;
  bomDescription: string;
  bomDrawingNo: string;
  bomItemGroup: string; // PAMF
  bomFormulation?: string;
  bomDefaultRouteId?: string;
  bomRouteCode?: string;
};

type BOMComponent = {
  cmpSeqNo: number;
  cmpItemCode: string;
  cmpDescription: string;
  cmpQuantity: number;
};

type BOMRoute = {
  rteSeqNo: number;
  rteOpCode: string; // ZZ
  rteQuantityPer: number;
  rteLabourTime: number;
  rteOverheadTime: number;
};

type BOMRouting = {
  route: BOMRoute[];
};

type BOM = {
  bomHeader: BOMHeader;
  bomComponents: {
    component: BOMComponent[];
  };
  bomRouting: BOMRouting;
};

type BOMS = {
  bom: BOM[];
};

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

const caseKeys = (obj: object): object => {
  if (obj && isObject(obj)) {
    Object.keys(obj)
      .sort()
      .forEach((key) => {
        const newKey = camelToSnakeCase(key);
        obj[newKey] = caseKeys(obj[key]);
        if (newKey !== key) {
          delete obj[key]; // Otherwise we delete the `document` and it all fails
        }
      });
  }

  return obj;
};

class Document {
  documentHeader: DocumentHeader;
  project: Project;
  order: Order;
  boms: BOMS;

  public get orderLineNumbers(): number[] {
    return this.order.orderLines.line.map((orderLine) => orderLine.lineNo);
  }

  public toXml(): string {
    const parser = new j2xParser({ format: true });
    const parsedXml: string = parser.parse({ document: caseKeys({ ...this }) });

    return `<?xml version="1.0" encoding="utf-8" ?>\n${parsedXml}`;
  }
}

export { OrderLine, BOM, BOMComponent };

export default Document;
