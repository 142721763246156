import * as yup from 'yup';

import {
  Choice,
  Input,
  Select,
  Button,
  Image,
  Attribute,
} from '@bespohk/uikit/components';
import {
  categoryOptions,
  choiceFromBoolean,
  choiceToBoolean,
  paginatedTypeOptions,
} from '@app/helpers/form';

import { Definition } from '@app/models/component';
import { ModelForm } from '@app/containers';
import { Pagination } from '@app/state/ducks/types';
import * as React from 'react';
import Series from '@app/models/series';
import Type from '@app/models/type';
import { lookup, post } from '@app/helpers/api';
import Dropzone from 'react-dropzone';
import { upload, ReadFile } from '@app/helpers/upload';
import { useParams } from 'react-router-dom';
import ToolType from '@app/models/tool-type';

type OwnProps = unknown;

const performUpload = (file: ReadFile, name: string, path: string) => {
  return post(path, file.data, null, {
    headers: {
      'Content-Type': file.file.type,
      'Content-Disposition': `attachment; filename=${name}`,
    },
  });
};

const Component = (_: OwnProps) => {
  const [svg, setSVG] = React.useState();
  const params: any = useParams();

  return (
    <ModelForm
      identifier="uuid"
      endpoint="/components"
      type={Definition}
      initialTransform={yup.object().shape({
        favorite: choiceFromBoolean(),
        enabled: choiceFromBoolean(),
        isOwnWallbox: choiceFromBoolean(),
        series: yup.array().convert('.'),
        toolTypes: yup.array().convert('.'),
        type: yup.mixed().convert('.'),
        createdDate: yup.mixed().strip(true),
        updatedDate: yup.mixed().strip(true),
        uuid: yup.mixed().strip(true),
      })}
      preValidateTransform={yup.object().shape({
        enabled: choiceToBoolean(),
        favorite: choiceToBoolean(),
        isOwnWallbox: choiceToBoolean(),
        type: yup.mixed().convert('id'),
        toolTypes: yup.array().convert('id'),
        series: yup.array().convert('id'),
      })}
      validate={yup.object().shape({
        description: yup.string().required(),
        verticalPunchPositions: yup.string().required(),
        screwCount: yup.number().required(),
        shortDescription: yup.string().required(),
        legendDescription: yup.string().required(),
        code: yup.string().required(),
        equipmentCode: yup.string().required(),
        quantity: yup.string().required(),
        width: yup.string().required(),
        descriptionCharacter: yup.string().required(),
      })}
      fieldsets={[
        [
          {
            name: 'shortDescription',
            renderer: Input,
            props: { type: 'text', required: true },
          },
          {
            name: 'description',
            renderer: Input,
            props: { type: 'text', required: true },
          },
        ],
        [
          {
            name: 'legendDescription',
            renderer: Input,
            props: { type: 'text', required: true },
          },
        ],
        [
          {
            name: 'code',
            renderer: Input,
            props: { type: 'text', required: true },
          },
          {
            name: 'equipmentCode',
            renderer: Input,
            props: { type: 'text', required: true },
          },
        ],
        [
          {
            name: 'quantity',
            renderer: Input,
            props: { type: 'number', required: true },
          },
          {
            name: 'width',
            renderer: Input,
            props: { type: 'number', required: true },
          },
        ],
        [
          {
            name: 'descriptionCharacter',
            renderer: Input,
            props: { type: 'text', required: true },
          },
          null,
        ],
        [
          {
            name: 'category',
            renderer: Select,
            props: {
              options: categoryOptions,
              openOnFocus: true,
              required: true,
            },
          },
          {
            name: 'type',
            renderer: Select,
            props: {
              async: lookup(Type, '/components/types', paginatedTypeOptions),
              openOnFocus: true,
              required: true,
            },
          },
        ],
        [
          {
            name: 'series',
            renderer: Select,
            props: {
              multiple: true,
              openOnFocus: true,
              required: true,
              async: lookup(Series, '/series', (data: Pagination<Series>) =>
                data.results.map((series) => ({
                  label: series.name,
                  value: series,
                })),
              ),
            },
          },
          {
            name: 'toolTypes',
            renderer: Select,
            props: {
              multiple: true,
              openOnFocus: true,
              required: true,
              typeThreshold: 0,
              searchThreshold: 0,
              async: lookup(
                ToolType,
                '/components/tool-types?limit=500',
                (data: Pagination<ToolType>) =>
                  data.results.map((tooltype) => ({
                    label: tooltype.code,
                    value: tooltype,
                  })),
              ),
            },
          },
        ],
        [
          {
            name: 'cost',
            renderer: Input,
            props: { type: 'number', required: true },
          },
          null,
        ],
        [{ title: 'Grid Counts and BOMs' }],
        [
          {
            name: 'bomDescription',
            renderer: Input,
          },
          {
            name: 'bomPartNumber',
            renderer: Input,
          },
          {
            name: 'punchCode',
            renderer: Input,
          },
        ],
        [
          {
            name: 'labourTime',
            renderer: Input,
            props: { type: 'number', required: true },
          },
          {
            name: 'psRouteAllocation',
            label: 'PS Route Allocation',
            renderer: Input,
            props: { type: 'number', required: true },
          },
        ],
        [
          {
            name: 'horizontalPunchPositions',
            label: 'Horizontal Punch Positions (number, defaults to 1)',
            renderer: Input,
          },
          {
            name: 'verticalPunchPositions',
            label: 'Vertical Punch Positions (comma separated)',
            renderer: Input,
            props: { required: true },
          },
        ],
        [
          {
            name: 'screwCount',
            label: 'Screw Count',
            renderer: Input,
            props: { required: true, type: 'number' },
          },
          null,
          null,
        ],
        [{ title: 'Options' }],
        [
          {
            name: 'favorite',
            label: null,
            renderer: Choice,
            props: {
              options: [{ value: true, label: 'Shows in Favorite list' }],
            },
          },
        ],
        [
          {
            name: 'isOwnWallbox',
            label: null,
            renderer: Choice,
            props: {
              options: [{ value: true, label: 'Is in own wallbox' }],
            },
          },
        ],
        [
          {
            name: 'enabled',
            label: null,
            renderer: Choice,
            props: {
              options: [{ value: true, label: 'Available on Morris' }],
            },
          },
        ],
        [
          {
            name: 'labelOffset',
            label:
              'The x,y position of any labels that are overlayed on the component',
            renderer: Input,
          },
        ],
      ]}
    >
      {(values) => {
        return (
          <div
            style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}
          >
            {(svg || values?.svg) && (
              <Image
                src={
                  svg ||
                  `${process.env.ASSET_BASE}/img/components/${
                    values.svg
                  }?d=${Date.now()}`
                }
              />
            )}
            {values && params.uuid && (
              <Attribute
                name="File (when uploaded)"
                value={`${process.env.ASSET_BASE}/img/components/${
                  values.svg
                }?d=${Date.now()}`}
              />
            )}
            {values && params.uuid && (
              <Dropzone
                accept={['.svg']}
                multiple={false}
                onDrop={(acceptedFiles) => {
                  const files = upload(acceptedFiles);

                  return files
                    .then((files_) => files_[0])
                    .then((file) => {
                      const svg_ = `${values.equipmentCode}-${values.quantity}-${values.width}.svg`;

                      return performUpload(
                        file,
                        svg_,
                        `/components/svg/${values.uuid}`,
                      );
                    })
                    .then((svg_) => setSVG(svg_.file));
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Button label="Upload SVG" />
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
          </div>
        );
      }}
    </ModelForm>
  );
};

export { Component };
export default Component;
